var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"admin page-list"},[_c('v-card',[_c('v-card-title',[_c('h1',[_vm._v("Administradores")])]),_c('v-data-table',{attrs:{"mobile-breakpoint":768,"headers":_vm.tableHeaders,"items":_vm.listData,"search":_vm.search,"loading":_vm.loaderTable,"footer-props":{
        itemsPerPageOptions: [10, 25, 50, 100, -1],
      },"loading-text":"Cargando... Por favor espere."},scopedSlots:_vm._u([{key:"item.dni",fn:function(ref){
      var item = ref.item;
return [_vm._v(" "+_vm._s(_vm._f("unitMile")(item.dni))+" ")]}},{key:"item.actions",fn:function(ref){
      var item = ref.item;
      var index = ref.index;
return [_c('CustomButtonAction',{attrs:{"value":item,"btnDetail":true,"btnEdit":true,"btnRecovery":true,"btnDelete":true,"index":index,"loaderRecovery":_vm.loaderRecovery,"loaderDelete":_vm.loaderDelete},on:{"onChangeDetail":function($event){return _vm.detailItem($event)},"onChangeEdit":function($event){return _vm.editItem($event)},"onChangeRecovery":function($event){return _vm.recoveryItem($event, index)},"onChangeDelete":function($event){return _vm.deleteItem($event, index)}}})]}},{key:"top",fn:function(){return [_c('v-toolbar',{attrs:{"flat":""}},[_c('v-row',[_c('v-col',{attrs:{"cols":"11","md":"6"}},[_c('v-text-field',{attrs:{"append-icon":"mdi-magnify","label":"Buscar ...","single-line":"","hide-details":"","outlined":"","dense":"","clearable":"","color":"second"},model:{value:(_vm.search),callback:function ($$v) {_vm.search=$$v},expression:"search"}})],1)],1),_c('v-spacer'),_c('EditAdmin',{attrs:{"dialog":_vm.editModal,"edit":_vm.editView,"data":_vm.selectItem},on:{"update:dialog":function($event){_vm.editModal=$event},"update:edit":function($event){_vm.editView=$event},"onChange":function($event){return _vm.firstRowData($event)}}}),_c('CustomDetails',{attrs:{"dialog":_vm.detailModal,"item":_vm.selectItem,"type":'admin'},on:{"update:dialog":function($event){_vm.detailModal=$event}}}),_c('CustomDialog',{attrs:{"dialog":_vm.deleteModal,"title":'Confirmar eliminación',"message":'¿Estás seguro que quieres eliminar el registro?',"maxWidth":'320'},on:{"update:dialog":function($event){_vm.deleteModal=$event},"onChange":function($event){return _vm.btnDelete()}}})],1),_c('v-divider')]},proxy:true}],null,true)})],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }
<template>
  <div class="admin page-list">
    <v-card>
      <v-card-title> <h1>Administradores</h1> </v-card-title>
      <v-data-table
        :mobile-breakpoint="768"
        :headers="tableHeaders"
        :items="listData"
        :search="search"
        :loading="loaderTable"
        :footer-props="{
          itemsPerPageOptions: [10, 25, 50, 100, -1],
        }"
        loading-text="Cargando... Por favor espere."
      >
        <template v-slot:[`item.dni`]="{ item }">
          {{ item.dni | unitMile }}
        </template>

        <template v-slot:[`item.actions`]="{ item, index }">
          <CustomButtonAction
            :value="item"
            :btnDetail="true"
            :btnEdit="true"
            :btnRecovery="true"
            :btnDelete="true"
            :index="index"
            :loaderRecovery="loaderRecovery"
            :loaderDelete="loaderDelete"
            @onChangeDetail="detailItem($event)"
            @onChangeEdit="editItem($event)"
            @onChangeRecovery="recoveryItem($event, index)"
            @onChangeDelete="deleteItem($event, index)"
          />
        </template>

        <template v-slot:top>
          <v-toolbar flat>
            <v-row>
              <v-col cols="11" md="6">
                <v-text-field
                  v-model="search"
                  append-icon="mdi-magnify"
                  label="Buscar ..."
                  single-line
                  hide-details
                  outlined
                  dense
                  clearable
                  color="second"
                ></v-text-field>
              </v-col>
            </v-row>

            <v-spacer></v-spacer>

            <EditAdmin
              :dialog.sync="editModal"
              :edit.sync="editView"
              :data="selectItem"
              @onChange="firstRowData($event)"
            />

            <CustomDetails
              :dialog.sync="detailModal"
              :item="selectItem"
              :type="'admin'"
            />

            <CustomDialog
              :dialog.sync="deleteModal"
              :title="'Confirmar eliminación'"
              :message="'¿Estás seguro que quieres eliminar el registro?'"
              :maxWidth="'320'"
              @onChange="btnDelete()"
            ></CustomDialog>
          </v-toolbar>
          <v-divider></v-divider>
        </template>
      </v-data-table>
    </v-card>
  </div>
</template>

<script>
import useColumns from "./useColumns";
import EditAdmin from "./edit-admin.vue";
import UserServices from "../../../../core/services/user-services";
import AdminStorage from "../../../../core/storage/admin-storage";
import CustomButtonAction from "../../../../shared/components/custom-button-action/CustomButtonAction.vue";
import CustomDetails from "../../../../shared/components/custom-details/CustomDetails.vue";
import CustomDialog from "../../../../shared/components/custom-dialog/CustomDialog.vue";

export default {
  name: "Admin",
  data: () => ({
    search: "",
    editView: false,
    editModal: false,
    detailModal: false,
    deleteModal: false,
    selectItem: {},
    listData: [],
    loaderTable: false,
    loaderRecovery: {},
    loaderDelete: {},
    selectIndex: null,
    tableHeaders: useColumns,
  }),
  components: {
    EditAdmin,
    CustomButtonAction,
    CustomDetails,
    CustomDialog,
  },
  created() {
    this.getAdmin();
    this.getAllUser();
  },
  methods: {
    detailItem(item) {
      this.detailModal = true;
      this.selectItem = item;
    },
    editItem(item) {
      this.editModal = true;
      this.editView = true;
      this.selectItem = item;
    },
    recoveryItem(item, index) {
      this.loaderRecovery = { [index]: true };
      const { recoveryPasswordUser } = UserServices();
      recoveryPasswordUser(item.email).then((resp) => {
        const response = resp.data;
        if (response.code == 202) {
          this.$toast.success(
            "Se ha enviando la solicitud para restablecer la contraseña"
          );
        }
        this.loaderRecovery = { [index]: false };
      });
    },
    deleteItem(item, index) {
      this.deleteModal = true;
      this.selectItem = item;
      this.selectIndex = index;
    },
    async getAdmin() {
      const { allAdmin } = AdminStorage();
      this.listData = await allAdmin();
    },
    getAllUser() {
      this.loaderTable = true;
      const { userAll } = UserServices();
      const { addAdmin } = AdminStorage();
      userAll(3).then((resp) => {
        const response = resp.data;
        if (response.code == 200) {
          response.data.map((item) => {
            addAdmin(item);
          });
          this.getAdmin();
        }
        this.loaderTable = false;
      });
    },
    firstRowData(data) {
      const { addAdmin } = AdminStorage();
      addAdmin(data);
      this.getAdmin();
    },
    btnDelete() {
      const { deleteUser } = UserServices();
      const { deleteAdminById } = AdminStorage();
      const index = this.selectIndex;
      this.loaderDelete = { [index]: true };

      deleteUser(this.selectItem.id).then((resp) => {
        const response = resp.data;
        if (response.code == 200) {
          deleteAdminById(this.selectItem.id);
          this.getAdmin();
          this.$toast.success("El registro ha sido eliminado exitosamente");
        }
        this.loaderDelete = { [index]: false };
      });
    },
  },
};
</script>

import { db } from "./db.js";

const AdminStorage = () => {
  const addAdmin = async (data) => {
    await db.admin.put(data);
  };

  const allAdmin = async () => {
    return await db.admin.orderBy("createdAt").reverse().toArray();
  };

  const deleteAdminById = async (id) => {
    await db.admin.delete(id);
  };

  const clearAdmin = async () => {
    await db.admin.clear();
  };

  return {
    addAdmin,
    allAdmin,
    deleteAdminById,
    clearAdmin,
  };
};

export default AdminStorage;

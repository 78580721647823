const useColumns = [
  {
    text: "Cédula",
    align: "start",
    value: "dni",
    class: "header-title",
    sortable: false,
  },
  {
    text: "Nombre",
    align: "start",
    value: "name",
    class: "header-title",
    sortable: false,
  },
  {
    text: "Apellido",
    align: "start",
    value: "lastName",
    class: "header-title",
    sortable: false,
  },
  {
    text: "Correo electrónico",
    align: "start",
    value: "email",
    class: "header-title",
    sortable: false,
  },
  {
    text: "Teléfono",
    align: "start",
    value: "phone",
    class: "header-title",
    sortable: false,
  },
  {
    text: "Acción",
    value: "actions",
    sortable: false,
    class: "header-title",
  },
];

export default useColumns;
